<template>
  <div class="content">
    <!-- <div class="page-content container-fluid"></div> -->
    <b-container>
      <PageHeader :title="$t('msg-settings.header-2')" />
      <div class="card">
        <h5 class="card-header" v-html="$t('msg-settings.card-header')"></h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h6>{{$t('msg-settings.start')}}</h6>
              <div>
                <input
                  class="tgl tgl-light tgl-primary department-modal-change"
                  id="live-active"
                  type="checkbox"
                />
                <label class="tgl-btn" for="live-active"></label> {{$t('generic-str.yes')}}
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-8">
                  <input type="" name="">
                </div>
                <div class="col-md-4">
                  <input type="" name="">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h6>{{$t('msg-settings.start')}}</h6>
              <div>
                <input
                  class="tgl tgl-light tgl-primary department-modal-change"
                  id="live-active"
                  type="checkbox"
                />
                <label class="tgl-btn" for="live-active"></label> {{$t('generic-str.yes')}}
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <input type="" name="">
                </div>
                <div class="col-md-6">
                  <input type="" name="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-primary">{{$t('generic-str.save')}}</button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'Users',
  components: {
    PageHeader,
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
};
</script>
<style scoped lang="scss">
.tgl-btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0 .8em 0 0;
}

h6 + div {
  padding-bottom: 1.5em;
}

.fieldset {
  padding: 1.5em;
}

.page-header::v-deep {
  padding: 30px 0;
}

.form-control {
  min-height: 100px;
}
</style>
